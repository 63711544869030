html {
  box-sizing: border-box;
}
*,
*:before,
*:after {
  box-sizing: inherit;
}

* {
  position: relative;
  margin: 0;
  padding: 0;
}

html {
  font-size: 16px;
}

body {
  margin: 0;
  padding: 0;
}

html,
body {
  width: 100vw;
  height: 100vh;
  overflow: hidden;
}

body {
  background: url("../public/static/bg-m.jpg") no-repeat center #2c3e50;
  background-size: cover;
  color: #fff;
}

body,
table {
  font: 1rem/1.618 din-2014, sans-serif;
  font-weight: 200;
}

b {
  font-weight: 500;
}

.instructions {
  position: fixed;
  font-size: 1rem;
  bottom: 0;
  left: 0;
  padding: 1rem;
  color: #eee;
  z-index: 10000;
}
.instructions a {
  color: #fff;
}

.clash {
  top: calc(50vh - 50vmin);
  width: 100vmin;
  height: 100vmin;
  margin: 0 auto;
  background: rgba(255, 255, 255, 0.03);
}

@media screen and (max-width: 400px) and (min-height: 400px) {
  .clash {
    top: 30vmin;
  }
}

.clash-tile {
  display: inline-block;
  float: left;
}
.clash-tile:nth-child(odd) {
  background: rgba(255, 255, 255, 0.03);
}

.clash-ammo {
  position: absolute;
  background: url("../public/static/star.png") no-repeat center / 80%;
}
.clash-ammo:hover {
  transform: rotate(360deg);
}

.clash-player {
  position: absolute;
  top: 0;
  left: 0;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
}
.clash-player-container {
  position: absolute;
  top: 0;
  left: 0;
}
.clash-player-name {
  top: -2rem;
  width: 8rem;
  left: 50%;
  margin-left: -4rem;
  text-transform: uppercase;
  text-align: center;
  font-size: 0.75rem;
}

@media screen and (max-width: 640px) {
  .clash-player-name {
    font-size: 0.55rem;
  }
}

.clash-shoot {
  position: absolute;
  width: 100em;
  transform-origin: center left;
}

.clash-layer {
  position: absolute;
  top: 0;
  left: 0;
  width: 100vmin;
  height: 100vmin;
}

.player-dead-emoji {
  font-size: 1rem;
  line-height: 1rem;
  opacity: 0;
  transform: scale(0);
  transition: opacity 0.3s, transform 0.3s;
}

.player-stats.-dead td {
  color: #55555;
}

td.player-name {
  text-align: left;
}

.player-stats.-dead .player-name {
  text-decoration: line-through;
}
.player-stats.-dead .player-dead-emoji {
  transform: scale(1);
  opacity: 1;
}

.notifications,
.stats,
.debugger {
  background: rgba(255, 255, 255, 0.05);
  /*box-shadow: rgba(0, 0, 0, 0.05) 0 1px 0, rgba(0, 0, 0, 0.1) 0 8px 16px;*/
  color: #fff;
  backdrop-filter: blur(16px);
  -webkit-backdrop-filter: blur(16px);
  width: 280px;
  margin: 16px;
  border-radius: 10px;
  overflow: hidden;
}

.notifications-element {
  /*padding: 0.5rem 1rem;*/
  padding: 0;
  margin: 0;
  max-height: 4rem;
  opacity: 1;
  transition: max-height 0.3s, opacity 0.3s;
}

.notifications-element.-hide {
  opacity: 0;
  overflow: hidden;
  max-height: 0;
  /*height: 2rem;*/
}

.notifications-element-text {
  padding: 0.25rem 1rem;
}

/*.notifications-element:not(:first-of-type) {
  border-top: 1px solid rgba(255,255,255,0.1);
}*/

.notifications-element.-alt {
  background-color: rgba(0, 0, 0, 0.2);
}

@media screen and (max-width: 640px) {
  .notifications {
    display: none;
  }
}

.stats {
  position: fixed;
  top: 0;
  left: 0;
  padding: 0.5rem 0;
  z-index: 10;
}

.stats-title {
  text-align: center;
}

.stats table {
  width: 100%;
}

.stats td,
.stats th {
  padding: 0.05rem 1rem;
  text-align: left;
}
.stats td.stats-results {
  text-align: right;
}

.notifications {
  position: fixed;
  bottom: 0%;
  right: 0%;
  z-index: 9000;
}

.animation-shot {
  animation: shotKeyFrame 0.3s;
}

.debugger {
  padding: 1rem;
  font-size: 0.8rem;
  line-height: 0.8rem;
  position: fixed;
  top: 60px;
  left: 0;
}

@keyframes shotKeyFrame {
  0% {
    background: #f00;
    margin-top: -0.05em;
    height: 0.1em;
  }
  100% {
    background: #ff0;
    height: 0.7em;
    margin-top: -0.35em;
  }
}

.animation-glow {
  animation: glowKeyFrame 1s infinite alternate;
}
@keyframes glowKeyFrame {
  0% {
    -webkit-filter: saturate(0);
  }
  100% {
    -webkit-filter: saturate(1);
  }
}

.-clickable {
  cursor: pointer;
  -webkit-user-select: none;
  user-select: none;
}
